/* eslint-disable react/no-danger */
import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'

import Scroll from '../components/Scroll'

// import config from '../../config'

const IndexPage = ({ data }) => {
  const { homePage, section, testimonials } = data.directus
  const sections = section.map((sc, i) => {
    return (
      <section key={`section_${i}`} className="wrapper style1">
        <div className="inner">
          <div dangerouslySetInnerHTML={{ __html: sc.body }} />
        </div>
        <div style={{ height: 32 }} />
      </section>
    )
  })
  const testim = testimonials.map((sc, i) => {
    return (
      <section key={`section_${i}`} className="wrapper-ts style1">
        <div className="inner">
          <div className="inner_blockquote">
            <blockquote>
              <div dangerouslySetInnerHTML={{ __html: sc.testimonial }} />
            </blockquote>
            <div className="testimonial">
              <div>{sc.testimonialAuthor}</div>
              <div>{sc.testimonialLocation}</div>
            </div>
          </div>
        </div>
      </section>
    )
  })
  return (
    <Layout image={homePage.backgroundImage}>
      <section id="banner">
        <div className="inner">
          <h2>{homePage.title}</h2>
          <p>{homePage.subTitle}</p>
        </div>
        <Scroll type="id" element="one">
          <a href="#one" className="more">
            Learn More
          </a>
        </Scroll>
      </section>
      <div id="one">{sections}</div>
      <section className="wrapper-ts style1">
        <div className="inner">
          <h2>Testimonials</h2>
        </div>
      </section>
      <div id="two">{testim}</div>
      <section className="wrapper-ts style1">
        <div className="inner" />
        <div style={{ height: 32 }} />
      </section>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage {
    directus {
      homePage {
        title
        subTitle
        buttonText
        backgroundImage {
          id
        }
      }
      section {
        body
      }
      testimonials {
        testimonial
        testimonialAuthor
        testimonialLocation
      }
    }
  }
`
